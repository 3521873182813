import { EVENT_NAME as FAVORITE_EVENT } from '@adobe-fonts/site/events/FavoriteEvent.js';
import { toggleFavorite } from '../../util/FamilyFavorite.js';
import { getService as service } from '../../util/angular_helpers.js';
import { addPageAction } from '../../util/new_relic.js';

export function registerEventHandlers(element) {
  element.addEventListener(FAVORITE_EVENT, handleFavoriteEvent);
}

async function handleFavoriteEvent(event) {
  const button = event.target;
  const card = button.closest('[data-slug]');

  if (!card || !('slug' in card.dataset)) {
    return;
  }

  const slug = card.dataset.slug;
  const shouldAdd = !button.hasAttribute('selected');
  if (shouldAdd) {
    const AuthenticationService = service('AuthenticationService');
    await AuthenticationService.handleAuthenticationWithPostLoginAction('favorite_fonts', { familySlug: slug });
  }
  await toggleFavorite(slug, shouldAdd);
  button.toggleAttribute('selected', shouldAdd);

  const familyName = card.dataset.familyName;
  sendFavoriteButtonAnalytics(slug, familyName, shouldAdd);
}

export function sendFavoriteButtonAnalytics(slug, family, add = false) {
  const buttonString = add ? 'typekit.click.add-favorite-family' : 'typekit.click.remove-favorite-family';
  addPageAction(buttonString, { slug: slug, family: family });
}
