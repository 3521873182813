var _ = require('underscore');

/**
 * A service used to interact with fontpacks on the backend.
 * @ngInject
 */
function FontpackService($cookies, $q, AuthenticationService, FirstMileService, NewrelicService, NotificationService, SyncService) {
  var _fontpacks;

  return {
    addFontpacks: addFontpacks,
    clearFontpacks: clearFontpacks,
    findFontpackBySlug: findFontpackBySlug,
    isFontpackSynced: isFontpackSynced,
    markFontsInFontpackAsSynced: markFontsInFontpackAsSynced,
    notSyncedCount: notSyncedCount,
    requiresUpgrade: requiresUpgrade,
    syncFontpack: syncFontpack,
    syncFontsInFontpack: syncFontsInFontpack,
    unsyncFontpack: unsyncFontpack,
    unsyncFontsInFontpack: unsyncFontsInFontpack,
    useModelUnsyncFontpack: useModelUnsyncFontpack
  };

  /**
   * Set font packs in the service so that states can be updated across multiple controllers
   * (for example, both the CollectionsController and FontpackItemsController on the Collections show page
   *  have actions on sync buttons).
   *
   * @returns Boolean
   */
  function addFontpacks(fontpacks) {
    _fontpacks = _.union(_fontpacks || [], fontpacks);
  }

  /**
   * Clear out font packs set by addFontpacks
   */
  function clearFontpacks() {
    _fontpacks = null;
  }

  /**
   * Returns the font pack with the slug provided.
   *
   * @param {String} fontpackSlug
   * @returns {Object}
   */
  function findFontpackBySlug(fontpackSlug) {
    return _.find(_fontpacks, function(fontpack) {
      return fontpack.slug === fontpackSlug;
    });
  }

  /**
   * Determines whether all of the fonts provided are already synced.
   *
   * @returns Boolean
   */
  function isFontpackSynced(fontpackSlug) {
    if (!_fontpacks) {
      return false;
    }
    var fontpack = this.findFontpackBySlug(fontpackSlug);
    return !!fontpack && _.all(fontpack.font_variations, function(font) {
      return font.sync.is_selected || (!!font.sync.install_state && _.contains(font.sync.install_state.split(','), 'cc'));
    });
  }

  /**
   * Returns how many of the fonts in the fontpack have not been synced.
   *
   * @param {Object} fontpack - The font pack being checked
   * @returns Integer
   */
  function notSyncedCount(fontpack) {
    if (!fontpack) {
      return 0;
    }
    return _.countBy(fontpack.font_variations, function(font) {
      return font.sync.is_selected || (!!font.sync.install_state && _.contains(font.sync.install_state.split(','), 'cc'));
    }).false || 0;
  }

  /**
   * Checks the variations in every font pack and marks them as synced if
   * they are in the syncedFontpack provided.
   *
   * @param {Object} syncedFontpack
   */
  function markFontsInFontpackAsSynced(syncedFontpack, isUseModelEnabled = false) {
    var variationIds = _variationIdsInFontpack(syncedFontpack);
    _.each(_fontpacks, function(fontpack) {
      _.each(fontpack.font_variations, function(font) {
        if (_.contains(variationIds, font.variation_id)) {
          if (isUseModelEnabled) {
            font.sync.install_state = 'cc';
          } else {
            font.sync.is_selected = true;
          }
        }
      });
    });
  }

  /**
   * Checks the variations in every font pack and marks them as unsynced if
   * they are in the unsyncedFontpack provided.
   * @param {Object} unsyncedFontpack
   */
  function markFontsAsUnsynced(unsyncedFontpack) {
    var variationIds = _variationIdsInFontpack(unsyncedFontpack);
    _.each(_fontpacks, function(fontpack) {
      _.each(fontpack.font_variations, function(font) {
        if (_.contains(variationIds, font.variation_id)) {
          font.sync.is_selected = false;
          font.sync.install_state = '';
        }
      });
    });
  }

  /**
   * Determines whether a plan upgrade is required in order to sync any of the fonts provided.
   *
   * @returns Boolean
   */
  function requiresUpgrade(fontpackSlug) {
    if (!_fontpacks) {
      return false;
    }
    var fontpack = this.findFontpackBySlug(fontpackSlug);
    return !!fontpack && _.any(fontpack.font_variations, function(font) {
      return font.sync.required_action.indexOf('upgrade') >= 0;
    });
  }

  /**
   * Handle authentication, report to NewRelic, display loading dialog,
   * sync the fonts in the fontpack, and update the variations upon success.
   *
   * @param {Object} fontpack
   * @param {String} actionSource
   * @param {Object} i18n
   * @returns {Promise}
   */
  function syncFontpack(fontpack, actionSource, i18n, isUseModelEnabled = false) {
    var self = this;

    return AuthenticationService.handleAuthenticationWithPostLoginAction('activate_collection', {slug: fontpack.slug, name: fontpack.name}).then(function() {
      var activatedCount = _.countBy(fontpack.font_variations, function(fontVariation) {
        if (fontVariation.sync.is_selected === true || _.contains(fontVariation.sync.install_state.split(','), 'cc')) {
          return 'activated';
        } else {
          return 'notActivated';
        }
      })['activated'] || 0;

      NewrelicService.addPageAction('typekit.click.sync-fontpack.' + actionSource, {
        activatedCount: activatedCount,
        fontpack: fontpack.slug,
        sawFontpacksOnBrowsePage: $cookies.get('sawCollections') || false,
        useModelEnabled: isUseModelEnabled
      });

      return self.syncFontsInFontpack(fontpack).then(function() {
        self.markFontsInFontpackAsSynced(fontpack, isUseModelEnabled);
        if (isUseModelEnabled) {
          FirstMileService.onUseModelFontActivation(i18n, {namespace: 'neue.fontpacks'});
        } else {
          NotificationService.success(fontpack.sync_success_message);
        }
      }, function() {
        if (isUseModelEnabled) {
          NotificationService.error(i18n['neue.fontpacks']['adding_font_pack_error_message'], {
            actionLabel: i18n['neue.fontpacks']['try_again'],
            callbackAction: function() { self.syncFontpack(fontpack, actionSource, i18n, isUseModelEnabled); }});
        } else {
          NotificationService.error(i18n['neue.fontpacks']['sync_error_message']);
        }
      });
    });
  }

  /**
   * Sync all of the fonts in the fontpack.
   *
   * @param {Object} fontpack
   * @returns {Promise}
   */
  function syncFontsInFontpack(fontpack) {
    var variationIds = _variationIdsInFontpack(fontpack);

    return $q(function(resolve, reject) {
      SyncService.addSyncedVariations(variationIds, {fontpack_id: fontpack.id}, notSyncedCount(fontpack)).then(function(response) {
        if (_isSuccessfulResponse(response, variationIds)) {
          resolve();
        } else {
          reject();
        }
      }, function() {
        reject();
      });
    });
  }

  function unsyncFontsInFontpack(fontpack, actionSource, i18n, isUseModelEnabled = false) {
    if (!isUseModelEnabled) {
      this.unsyncFontpack(fontpack, actionSource, i18n);
    } else {
      var self = this;
      FirstMileService.shouldShowRemoveFamilyConfirmationDialog().then(function(shouldShow) {
        if (shouldShow) {
          var header = i18n['neue.fontpacks']['remove_font_pack_confirmation_header'];
          var description = i18n['neue.fontpacks']['remove_font_pack_confirmation_description'];
          return FirstMileService.showRemoveFamilyConfirmationDialog(header, description, function() {
            self.useModelUnsyncFontpack(fontpack, actionSource, i18n);
          });
        } else {
          self.useModelUnsyncFontpack(fontpack, actionSource, i18n);
        }
      });
    }
  }

  /**
   * Unsync all of the fonts in the fontpack when usemodel v2 is enabled.
   *
   * @param {Object} fontpack
   * @param {String} actionSource
   * @param {Object} i18n
   */
  function useModelUnsyncFontpack(fontpack, actionSource, i18n) {
    var self = this;
    NewrelicService.addPageAction('typekit.click.unsync-fontpack.' + actionSource, {
      fontpack: fontpack.slug,
      sawFontpacksOnBrowsePage: $cookies.get('sawCollections') || false,
      useModelEnabled: true
    });

    SyncService.removeSyncedVariations(_variationIdsInFontpack(fontpack), {fontpack_id: fontpack.id}).then(function() {
      markFontsAsUnsynced(fontpack);
      NotificationService.notice(i18n['neue.fontpacks']['fonts_removed_success_message']);
    }, function() {
      NotificationService.error(i18n['neue.fontpacks']['removing_font_pack_error_message'], {
        actionLabel: i18n['neue.fontpacks']['try_again'],
        callbackAction: function() { self.useModelUnsyncFontpack(fontpack, actionSource, i18n); }});
    });
  }

  /**
   * Unsync all of the fonts in the fontpack.
   *
   * @param {Object} fontpack
   * @param {String} actionSource
   * @param {Object} i18n
   * @returns {Promise}
   */
  function unsyncFontpack(fontpack, actionSource, i18n) {
    NewrelicService.addPageAction('typekit.click.unsync-fontpack.' + actionSource, {
      fontpack: fontpack.slug,
      sawFontpacksOnBrowsePage: $cookies.get('sawCollections') || false,
      useModelEnabled: false
    });

    return $q(function(resolve, reject) {
      return SyncService.removeSyncedVariations(_variationIdsInFontpack(fontpack), {fontpack_id: fontpack.id}).then(function() {
        markFontsAsUnsynced(fontpack);
        NotificationService.success(fontpack.unsync_success_message);
        resolve();
      }, function() {
        NotificationService.error(i18n['neue.fontpacks']['unsync_error_message']);
        reject();
      });
    });
  }

  /**
   * Does response from the server represent a successful sync action?
   *
   * @param {Object} response
   * @param {String[]} variationIds
   * @private
   * @returns {Boolean}
   */
  function _isSuccessfulResponse(response, variationIds) {
    if (response.status !== 200) {
      return false;
    }

    return _.every(variationIds, (function(variationId) {
      return 'data' in response && response.data['added_font_ids'].indexOf(variationId) >= 0;
    }));
  }

  /**
   * Returns the IDs of the variations in the font pack provided.
   *
   * @param {Object} fontpack
   * @private
   * @returns {Array}
   */
  function _variationIdsInFontpack(fontpack) {
    return _.map(fontpack.font_variations, function(item) {
      return item.variation_id;
    });
  }
}

module.exports = FontpackService;
