import { addPageAction, getNewRelicName } from '../../util/new_relic.js';
const pagination = document.querySelector('af-pagination');

/**
 * Updates the url's page to the value passed
 * @param {Number} currentPage
 */
export function updatePageUrl(currentPage) {
  let params = new URLSearchParams(window.location.search);
  params.set('page', currentPage);
  const newPage = window.location.pathname + '?' + params.toString();

  history.pushState(null, '', newPage);
  window.scrollTo({ top: 0, behavior: 'smooth' });

  updatePagination(currentPage);
}

/**
 * Updates the pagination component's current page to the value passed
 * @param {Number} currentPage
 *
 */
function updatePagination(currentPage) {
  if (!pagination) {
    return;
  }

  pagination.currentPage = currentPage;
}

/**
 * Send click analytics to NR with full pagination event details
 * from a specified page
 *
 * @param {String} page
 * @param {Event} event
 */
export function sendAnalytics(page, event) {
  if (!pagination) {
    return;
  }

  addPageAction(getNewRelicName(page, event), {
    currentPage: event.detail.page,
    totalPages: pagination.totalPages,
    pageButton: event.detail.pageButton
  });
}
